import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/shahid/Desktop/Projects/Personal/madrasatulilm.com/src/views/layouts/MainLayout.js";
import { Helmet } from 'react-helmet';
import { Box, Breadcrumb, Divider, SEO, Text, BookList, Button } from '../../views/components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO pageTitle="গণিত" mdxType="SEO" />
    <Box maxWidth={960} margin="0 auto" padding={{
      xs: 3,
      sm: 4
    }} mdxType="Box">
      <Text variant="h2" textAlign="center" mdxType="Text">
  গণিত
      </Text>
      <Divider mdxType="Divider" />
      <Breadcrumb links={[{
        url: '/',
        name: 'নীড়পাতা'
      }, {
        url: '/study-materials/',
        name: 'শিক্ষাপোকরণ'
      }, {
        url: '/study-materials/mathematics',
        name: 'গণিত',
        isCurrentPage: true
      }]} mdxType="Breadcrumb" />
      <Divider mdxType="Divider" />
      <Text variant="h4" color="primary2" mdxType="Text">
  গুণের নামতার কার্ড
      </Text>
      <p>{`০ থেকে ১১ পর্যন্ত গুণের নামতার কার্ডটা একটি A4 কাগজের উভয় পাশে কালার প্রিন্ট
করুন। পাতাটি ভাজ করে একটি দুই পাতার বইয়ের মতো বানান। তারপর ছাত্র/ছাত্রীকে
বলুন, ঠিক যেভাবে লেখা আছে সেভাবেই নামতাগুলো মুখস্থ করতে।`}</p>
      <Box textAlign="center" mdxType="Box">
  <a href="https://drive.google.com/file/d/1nbiA9Woi2tT-2XN9fPRht7KIbdrUDuvB/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">গুণের নামতার কার্ড ডাউনলোড</Button>
  </a>
      </Box>
      <Text variant="h4" color="primary2" mdxType="Text">
  সংখ্যা ও নামতার ধারণার অনুশীলন
      </Text>
      <p>{`Work sheet গুলো প্রিন্ট করে ছাত্র/ছাত্রীদের কাছে দিন। আশা করা যায়, এগুলো অনুশীলন করলে তাদের কাছে সংখ্যা ও নামতার ধারণা স্পষ্ট হবে, ইন শা আল্লাহ।`}</p>
      <Box textAlign="center" mdxType="Box">
  <a href="https://drive.google.com/file/d/1sJ6PbkEKVO4IwKyZ4PmHdN8cFyzmC0pk/view?usp=sharing" target="_blank" rel="noreferrer noopener">
    <Button variant="primary" mdxType="Button">Work sheet ডাউনলোড</Button>
  </a>
      </Box>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      